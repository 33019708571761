"use client";

import ButtonLogout from "@/components/ButtonLogout";
import { useAuth } from "@/hooks/useAuth";
import useUserAccount from "@/hooks/useUserAccount";
import Link from "next/link";
import AccountsList from "./accounts/AccountsList";
import LoginCard from "./LoginCard";

export default function LoggedInSection() {
  const { isLoggedIn } = useAuth();

  return (
    <div className="flex flex-col gap-4">
      {isLoggedIn ? <LoggedIn /> : <LoginCard />}
    </div>
  );
}

function LoggedIn() {
  const userAccount = useUserAccount();

  if (!userAccount) {
    return <></>;
  }

  return (
    <>
      <div className="flex items-center justify-between gap-2 sm:gap-4">
        <h1 className="text-2xl font-bold">Your Accounts</h1>
        <Link href={"/accounts"}>View All Accounts</Link>
      </div>
      <AccountsList userAccountId={userAccount.id} />
      <div className="flex justify-end">
        <ButtonLogout />
      </div>
    </>
  );
}
