"use client";

import { ButtonBasic } from "@/components/ButtonBasic";
import { useAuth } from "@/hooks/useAuth";
import { getFirebase } from "@/lib/getFirebase";
import getUserAccount from "@/lib/userAccount";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useRouter } from "next/navigation";

function loginWithGoogle() {
  const { auth } = getFirebase();
  const provider = new GoogleAuthProvider();

  signInWithPopup(auth, provider)
    .then((result) => {
      const user = result.user;
      getUserAccount(user);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.error(errorCode, errorMessage, email, credential);
    });
}

export default function LoginCard() {
  const { isLoggedIn, user } = useAuth();
  const router = useRouter();

  if (isLoggedIn && user) {
    router.push("/");
  }

  return (
    <div className="flex ">
      <ButtonBasic variant="secondary" onClick={() => loginWithGoogle()}>
        Login with Google
      </ButtonBasic>
    </div>
  );
}
